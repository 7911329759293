<template>
  <div class="boxPage">
    <img class="backImg" :src="formDetail.formBackImg">
    <el-form ref="studentForm" :model="studentForm" label-width="120px" class="demo-ruleForm">
      <el-form-item v-for="(item, index) in formList" :key="index" :label="item.formFieldName == '我已阅读并同意'
        ? ''
        : item.formFieldName == '单选' ||
          item.formFieldName == '多选' ||
          item.formFieldName == '下拉菜单' ||
          item.formFieldName == '上传图片' ||
          item.formFieldName == '文本框' ||
          item.formFieldName == '签名'
          ? item.formFieldNote || item.formFieldName
          : item.formFieldName
        " :required="item.formIsBlank == 1" :show-message="false">
        <!-- 标题 -->
        <!-- <div class="itemTitle" v-if="i.formFieldName != '我已阅读并同意' && i.formFieldType == 'text'">
          <span v-if="i.formIsBlank == '1'" class="require">*</span>
          {{ i.formFieldName }}
        </div>
        <div class="itemTitle" v-if="i.formFieldName != '我已阅读并同意' && i.formFieldType != 'text'">
          <span v-if="i.formIsBlank == '1'" class="require">*</span>
          {{ i.formFieldNote || i.formFieldName }}
        </div> -->
        <!-- 输入框 -->
        <el-col v-if="item.formFieldType == 'text' && item.formFieldName != '我已阅读并同意'" :span="8">
          <el-input v-model="item.formInputVal" class="input"
            :placeholder="item.formFieldNote ? item.formFieldNote : ''" />
        </el-col>
        <!-- 单选框 -->
        <el-radio-group v-if="item.formFieldType == 'radio' && item.subjson && item.formFieldName != '性别'"
          v-model="item.formInputVal" @change="radioChange($event, JSON.parse(item.subjson), index)">
          <el-radio v-for="(items, indexs) in JSON.parse(item.subjson)" :key="`${indexs + 1000}`"
            :label="items.formFieldName">{{ items.formFieldName }}</el-radio>
        </el-radio-group>
        <!-- 性别 -->
        <el-radio-group v-if="item.formFieldName == '性别'" v-model="item.formInputVal">
          <el-radio label="男">男</el-radio>
          <el-radio label="女">女</el-radio>
        </el-radio-group>
        <!-- 多选框 -->
        <el-checkbox-group v-if="item.formFieldType == 'checkbox' && item.subjson && item.subjson ? item.subjson.length > 0 : false
          " v-model="item.arr" @change="checkboxChange($event, JSON.parse(item.subjson), index)">
          <el-checkbox v-for="(items, indexs) in JSON.parse(item.subjson)" :key="`${indexs + 20000}`"
            :label="items.formFieldName" />
        </el-checkbox-group>
        <!-- 下拉选择框 -->
        <el-select v-if="item.formFieldType == 'select' && item.subjson && item.formFieldName != '我已阅读并同意'"
          v-model="item.formInputVal" placeholder="请选择" class="select"
          @change="selectChange($event, JSON.parse(item.subjson), index)">
          <el-option v-for="(items, indexs) in JSON.parse(item.subjson)" :key="`${indexs + 10000}`"
            :label="items.formFieldName" :value="items.formFieldName" />
        </el-select>
        <!-- 上传图片 -->
        <el-upload v-if="item.formFieldType == 'img'" :ref="`formBackImg${index}`" :show-file-list="false" name="upload"
          :headers="Domainjwt" :action="imgAction()" :on-success="(res, file, fileList) => {
            thumbnailSuccess(`formBackImg${index}`, item, res, file, fileList);
          }
            " :before-upload="imgurlBeforeUpload">
          <div class="dis_flex">
            <div class="avatar-uploader-sheji">
              <img v-if="item.formInputVal" :src="item.formInputVal" class="avatar">
              <div v-else class="icon">
                <i class="el-icon-plus avatar-uploader-icon" />
                <div>{{ item.formFieldNote }}</div>
              </div>
            </div>
            <div class="m-l-10">
              <div class="uploadBut">
                <el-button size="small" type="success">点击上传</el-button>
              </div>
              <div class="uploadTip">（仅支持图片格式，且不超过3M）</div>
            </div>
          </div>
        </el-upload>
        <!-- 选择地址 -->
        <div v-if="item.formFieldName == '地址'" class="contentBox">
          <el-row style="width: 540px">
            <el-col :span="8">
              <el-select v-model="item.province" style="margin-right: 10px;"
                @change="(e) => { provinceChange(e, index) }">
                <el-option v-for="province in provinceList" :key="province.id" :label="province.name"
                  :value="province.name" />
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="item.city" style="margin-right: 10px;" @change="(e) => { cityChange(e, index) }">
                <el-option v-for="city in cityList" :key="city.id" :label="city.name" :value="city.name" />
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="item.section" @change="(e) => { sectionChange(e, index) }">
                <el-option v-for="section in sectionList" :key="section.id" :label="section.name" :value="section.name" />
              </el-select>
            </el-col>
          </el-row>
        </div>
        <!-- 阅读并同意 -->
        <el-checkbox v-if="item.formFieldName == '我已阅读并同意'" v-model="checked">我已阅读并同意</el-checkbox>
        <div v-if="item.formFieldName == '我已阅读并同意'" />
        <el-col v-if="item.formFieldName == '我已阅读并同意'" :span="8">
          <el-input v-model="item.formFieldNote" class="input" disabled type="textarea" :rows="5" placeholder="请输入内容" />
        </el-col>
        <!-- <el-row>
          <el-col v-if="item.formFieldName == '地址'" :span="10">
            <el-select class="select" placeholder="请选择省"> </el-select>
          </el-col>
          <el-col v-if="item.formFieldName == '地址'" :span="10">
            <el-select class="select" placeholder="请选择市"> </el-select>
          </el-col>
        </el-row> -->
      </el-form-item>
    </el-form>
    <div v-show="checked" class="submitBtn" @click="submit">提交</div>
    <div v-show="!checked" class="unreadBtn">提交</div>
  </div>
</template>
<script>
import { getFormById, saveInputFormVal } from '@/api/home.js'
import { getInfo, getDomainToken } from '@/api/cookies'
import address from '@/assets/js/address.js'
export default {
  data() {
    return {
      studentForm: {
        formName: '',
        formNote: '',
        isbj: '0',
        formBackImg: '',
        formBigKindId: '',
        formKindId: '',
        Domainjwt: {
          Domainjwt: getDomainToken()
        },
        isShowgx: false,
        formThanksNote: '',
        formLinkUrl: '',
        isShowtz: false
      },
      formThanksNote: '',
      provinceList: [], // 省
      cityList: [],
      sectionList: [],
      rules: {
        formName: [{ required: true, message: '请输入表单标题', trigger: 'blur' }],
        formNote: [{ required: true, message: '请输入表单标题', trigger: 'blur' }],
        isbj: [{ required: true, message: '请选择图片', trigger: 'change' }],
        formBackImg: [{ required: true, message: '请上传图片', trigger: 'change' }],
        identityNo: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern:
              /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: '请输入合法身份证号',
            trigger: 'blur'
          }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ],
        formKindId: [{ required: true, message: '请选择表单分类', trigger: 'change' }],
        formThanksNote: [{ required: true, message: '请输入感谢信内容', trigger: 'blur' }],
        formLinkUrl: [{ required: true, message: '请输入跳转地址', trigger: 'blur' }]
      },
      collageList: [],
      formList: [],
      id: null, // 表单ID
      checked: true,
      formDetail: {},
      userInfo: null,
      shareFrom: null // 分享人ID
    }
  },
  async created() {
    this.id = await this.$route.query.id
    await this.getFormById()
    this.userInfo = getInfo()
    this.provinceList = address
  },
  methods: {
    /* 查询表单详情 */
    async getFormById() {
      const data = {
        formId: this.id,
        userid: this.userInfo ? this.userInfo.id : null,
        shareFrom: this.shareFrom
      }
      await getFormById(data).then((res) => {
        this.formDetail = res.data
        this.accessId = res.accessId
        this.formList = eval(res.data.fieldJson)
        this.formList.map((item) => {
          if (item.formFieldType == 'select') {
            item.pickerVal = 0
          }
          if (item.formFieldName == '我已阅读并同意') {
            this.checked = false
          }
          if (item.formFieldType == 'checkbox') {
            /* item.formInputVal = [] */
            this.$set(item, 'arr', [])
          }
        })
        this.formThanksNote = res.data.formThanksNote || '提交成功'
      })
      this.$forceUpdate()
    },
    /* 选择省份 */
    provinceChange(val, index) {
      this.$set(this.formList[index], 'city', '')
      this.cityList = []
      this.cityList = this.provinceList.filter(item => {
        return item.name == val
      })[0].children
    },
    cityChange(val, index) {
      this.$set(this.formList[index], 'section', '')
      this.sectionList = []
      this.sectionList = this.cityList.filter(item => {
        return item.name == val
      })[0].children
      if (this.sectionList.length == 0) {
        this.formList[index].formInputVal =
          this.formList[index].province + '-' + val
      }
    },
    sectionChange(val, index) {
      this.formList[index].formInputVal =
        this.formList[index].province +
        '-' +
        this.formList[index].city +
        '-' +
        val

    },
    /* 单选选择 */
    radioChange(val, subjson, index) {
      const obj = subjson.filter((item) => {
        return item.formFieldName == val
      })
      this.formList[index].formFieldId = obj[0].id
    },
    /* 多选选择 */
    checkboxChange(val, subjson, index) {
      const obj = subjson.filter((item) => {
        return val.includes(item.formFieldName)
      })
      this.formList[index].formInputVal = obj
    },
    /* 下拉选择 */
    selectChange(val, subjson, index) {
      const obj = subjson.filter((item) => {
        return item.formFieldName == val
      })
      this.formList[index].formFieldId = obj[0].id
    },
    /* 图片上传成功回调 */
    thumbnailSuccess(ref, i, res) {
      if (res.isSuccess) {
        this.$set(i, 'formInputVal', res.path)
      } else {
        this.$message.error(res.error)
        Vue.prototype.imgClearn(ref, this)
      }
    },
    /* 图片上传之前 */
    imgurlBeforeUpload(file, size) {
      const isRightSize = file.size / 1024 / 1024 < (size || 3)
      if (!isRightSize) {
        this.$message.error(`文件大小超过 ${size || 3}MB`)
      }
      return isRightSize
    },
    validateForm() {
      const list = this.formList.filter((d) => d.formIsBlank == '1')
      return list.some((d) => {


        if (
          (!d.formInputVal && d.formFieldName != '我已阅读并同意') ||
          (d.formFieldType == 'checkbox' && d.formInputVal.length == 0)
        ) {
          this.$message({
            type: 'warning',
            message: '请填写完全部内容后再提交'
          })
          return true
        } else {
          return false
        }
      })
    },
    /* 提交 */
    submit() {
      if (this.validateForm()) {
        return
      }
      if (this.checked) {
        const data = {
          jsonVal: [],
          formId: this.id,
          accessId: this.accessId,
          userid: this.userInfo ? this.userInfo.id : null
        }
        this.formList.map((item) => {
          if (item.formFieldType == 'checkbox' && item.formInputVal) {
            item.formInputVal.forEach((item) => {
              const obj = {
                formFieldId: item.id,
                formInputVal: item.formFieldName
              }
              data.jsonVal.push(obj)
            })
          } else if (
            (item.formFieldType == 'radio' && item.formFieldName != '性别') ||
            item.formFieldType == 'select'
          ) {
            if (item.formFieldName == '地址') {
              data.jsonVal.push({
                formFieldId: item.id,
                formInputVal: item.province
                  ? item.province + '-' + item.city ||
                  '' + (item.section ? '-' + item.section : '')
                  : ''
              })
            } else {
              const obj = {
                formFieldId: item.formFieldId,
                formInputVal: item.formInputVal
              }
              data.jsonVal.push(obj)
            }
          } else {
            const obj = {
              formFieldId: item.id,
              formInputVal: item.formInputVal
            }
            data.jsonVal.push(obj)
          }
        })
        for (let i = 0; i < data.jsonVal.length; i++) {
          if (!data.jsonVal[i].formInputVal || !data.jsonVal[i].formFieldId) {
            data.jsonVal.splice(i, 1)
            i--
          }
        }
        data.jsonVal = JSON.stringify(data.jsonVal)
        saveInputFormVal(data).then((res) => {
          if (res.code == 0) {
            this.$router.push({
              path: '/marketing/promotionSuccess?text=' + this.formThanksNote
            })
          } else {
            this.$message({
              message: res.msg,
              type: res.code == 0 ? 'success' : 'error'
            })
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: '请阅读条款'
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.boxPage {
  width: 750px;
  min-height: 100vh;
  margin: auto;
  padding: 40px 45px;
  background-color: #ffffff;

  .backImg {
    width: 646px;
    height: 275px;
    margin-bottom: 20px;
  }
}

.input {
  width: 540px;
}

.submitBtn {
  width: 660px;
  height: 40px;
  border-radius: 2px;
  background-color: #2a7af2;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 25px;
  cursor: pointer;
}

.unreadBtn {
  width: 660px;
  height: 40px;
  border-radius: 2px;
  background-color: #f5f6fa;
  color: rgb(0, 0, 0);
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 25px;
  cursor: pointer;
}
</style>
